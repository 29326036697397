import packageJson from '../../../../package.json';
import { AllocationMethod } from '../app/shared/models/allocation-method.enum';
import { DefaultMetricsGroup } from '../app/shared/models/default-metrics-group.enum';

export const environment: {
    appName: string;
    test: boolean;
    debug: boolean;
    attributes: boolean,
    apiUrl: string;
    production: boolean;
    listingPagination: boolean;
    envName: string;
    version: string;
    userManagementApiUrl: string;
    pictureManagerApiUrl: string;
    awsCognitoIdentityPoolId: string;
    awsUserPoolsId: string;
    awsUserPoolsWebClientId: string;
    pictureManagerCloudfrontDistribution: string;
    packlotEnabled: boolean;
    /**
     * Description for stylingMetricsSplit
     * 
     * Default: false
     * BRD: true
     * 
     * When stylingMetricsSplit is true, the getSizeValue function, in the styling component, splits the string 
     * and returns the number.
     * Associated code: return Number(String(item[size]).split('|')[0]);
     * 
     * When stylingMetricsSplit is false, the getSizeValue function, in the styling component, just returns the item size.
     * Associated code: return item[size];
     */
    stylingMetricsSplit: boolean;
    
    /**
     * Description for stylingExcludeStoreNumber
     * 
     * Default: false
     * BRD: true
     * 
     * When stylingExcludeStoreNumber is set to true, the getStoreName function, in the styling component, returns the 
     * store name WITHOUT the brackets and id.
     * Associated code: return item.store_name.substr(0, item.store_name.indexOf('(')).trim();
     * 
     * When stylingExcludeStoreNumber is set to false, the getStoreName function, in the styling component, returns the 
     * store name WITH the brackets and id.
     * Associated code: return item.store_name.substr(item.store_name.indexOf(')') + 2).trim();
     */
    stylingExcludeStoreNumber: boolean;

    /**
     * Description for allocationMethod
     * 
     * Duns: ExactMl
     * BRD: ExactAlgo
     * Default: ExactAlgo
     * 
     * This dictates the allocation method used in the determineAllocationColumn, in the workbench table component.
     * Associated code: return `allocated_${environment.allocationMethod}_${data.weeks_of_cover}wk_sls_u`;
     *                  return `allocated_${environment.allocationMethod}_${parameters.weeksOfCover}wk_sls_u`;
     *                  return 'allocated_${environment.allocationMethod}_${environment.allocationMethodNumberOfWeeks}wk_sls_u';
     */
    allocationMethod: AllocationMethod;

    /**
     * Description for allocationDefaultNumberOfWeeks
     * 
     * Default: 8
     * 
     * This dictates the number of weeks for the allocation method used in the determineAllocationColumn, in the workbench table component.
     * Associated code: return 'allocated_${environment.allocationMethod}_${environment.allocationMethodNumberOfWeeks}wk_sls_u';
     */
    allocationDefaultNumberOfWeeks: number;
    defaultMetricsGroup: DefaultMetricsGroup;
    canCreateAllocations: boolean;
    nextFrontendUrl: string;
} = {
    appName: "Lynkd Pattern Analytics",
    envName: 'PROD',
    debug: false,
    production: true,
    test: false,
    attributes: true,
    listingPagination: false,
    version: packageJson.version,
    apiUrl: 'https://dxkr32hu39.execute-api.af-south-1.amazonaws.com/uat',
    userManagementApiUrl: 'https://nzzxnwx67b.execute-api.af-south-1.amazonaws.com/prod',
    pictureManagerApiUrl: '',
    awsCognitoIdentityPoolId: 'eu-west-1:2e6ff77a-e620-41ca-8be1-484586ecffb9',
    awsUserPoolsId: 'eu-west-1_F6hx1tPvj',
    awsUserPoolsWebClientId: '5rt5rsf41jqhjbm0sfp6l3fdef',
    pictureManagerCloudfrontDistribution: '',
    packlotEnabled: false,
    stylingMetricsSplit: true,
    stylingExcludeStoreNumber: false,
    allocationMethod: AllocationMethod.ExactMl,
    allocationDefaultNumberOfWeeks: 8,
    defaultMetricsGroup: DefaultMetricsGroup.Ml,
    canCreateAllocations: false,
    nextFrontendUrl: ''
};
